import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectAttribute from "../project/projectAttribute"
import ProjectDetail from "../project/projectDetail"

const ChessupDetails = () => {
  return (
    <ProjectDetail
      projectIcon={
        <StaticImage
          src="../../images/imageProjectPreviewIconChessUp.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp logo."
        />
      }
      projectName="ChessUp App"
      projectOwner="Freelance"
      projectButtonLink="https://bryghtlabs.com/"
      projectButtonTitle="See it Live"
      projectButtonType=""
      projectDescription="ChessUp is the first project of Bryght Labs - a connected gaming startup funded by an experienced team of investors and  product developers. Bryght Labs’ mission is to make STEM-focused games more approachable. ChessUp mobile app is a companion for an incredible, connected chessboard with a built-in chess instructor."
      projectSolutions={<div>
        <ProjectAttribute
          type="linked"
          link="#onboarding"
          title="Onboarding"
          detail="Frictionless first-time user experience."
        />

        <ProjectAttribute
          type="linked"
          link="#play"
          title="Play"
          detail="All the games in one place."
        />

        <ProjectAttribute
          type="linked"
          link="#newGame"
          title="New Game"
          detail="Simple interface with progressively powerful controls."
        />

        <ProjectAttribute
          type="linked"
          link="#playing"
          title="Playing a Game"
          detail="Beautiful chess experience that doesn't get in the way."
        />

        <ProjectAttribute
          type="linked"
          link="#connecting"
          title="Connecting to Chessboard"
          detail="Quick and easy way to get connected and see the board status carried out through the entire app."
        />

        <ProjectAttribute
          type="linked"
          link="#learn"
          title="Learn"
          detail="Learning chess has never been so much fun!"
        />

        <ProjectAttribute
          type="linked"
          link="#practices"
          title="Practices"
          detail="Daily challenges for any skill level."
        />

        <ProjectAttribute
          type="linked"
          link="#activity"
          title="Activity"
          detail="Notifications, requests, and updates. All in one place."
        />

        <ProjectAttribute
          type="linked"
          link="#settings"
          title="Settings"
          detail="Who said settings should be boring?"
        />

        <ProjectAttribute
          type="linked"
          link="#android"
          title="Android"
          detail="I've designed a first-class Android app too!"
        />
      </div>}
      projectRole="Senior Product Designer"
      projectDuration="2021"
      projectSummary="Designed and prototyped a companion mobile app for the the #1 most funded chess product of all time.  As a freelance senior product designer, I was responsible for the end-to-end mobile app design process – from working with the co-founders on understanding the problems we wanted to solve to defining and desgining the solutions."
      projectAttributes={<div>
        <ProjectAttribute
          title="Design"
          detail="End-to-End Product Design, UI Design, Interaction Design, Prototyping, Figma"
        />

        <ProjectAttribute
          title="Platforms"
          detail="iOS, Android, BLE"
        />

        <ProjectAttribute
          title="Business"
          detail="Games, B2C, Hardware"
        />
      </div>}
    />
  )
}

export default ChessupDetails