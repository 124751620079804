import React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const ChessupSituation = () => {
  return (
  <ProjectStudyEpic
    title="Situation"
    subtitle=""
    content={
    <div className="epicRow">
      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          title="Audience"
          subtitle="ChessUp hardware was designed with everyone in mind. It doesn’t matter if you are new to chess or a master, young or old – the product was designed for anyone to pick up, play, and learn. The same audience would use the app which would enable them to elevate the experience with their hardware by enabling additional capabilities."
          images="noImages"
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          title="The Problem"
          subtitle="Creating a companion app for a physical product with specific requirements and constraints which needed to seamlessly integrate and elevate the physical product but not get in the way; but also work on its own and provide engaging learning and gameplay experience if the user didn’t have the physical hardware yet."
          images="noImages"
        />
      </div>

      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition="cover"
          cardStyle="primaryCell"
          title=""
          subtitle=""
          images="imageOnly"
          image1={<StaticImage
            src="../../images/chessup/situation.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="A ChessUp mockup."/>}
        />
      </div>
    </div>
    }
  />
  )
}

export default ChessupSituation