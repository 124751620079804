import React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"
import ProjectRecommendation from "../project/projectRecommendation"
import { StaticImage } from "gatsby-plugin-image"

const OutcomeOnboarding = () => {
  return (
  <ProjectStudyFeature
    id="onboarding"
    title="Onboarding"
    subtitle=""
    content={<>
      <div className="featureRow">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="Great first impression."
          subtitle="When players open the app for the first time, they are greeted by clear value proposition and an easy way to sign up."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/onboarding1.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup."/>}
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          textStyle="unifiedText"
          title="No socials? No problem."
          subtitle="Creating an account with an email address is simple and straightforward."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/onboarding2.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup." />}
        />
      </div>

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Natural conversation instead of filling out a form."
        subtitle="Players are asked only the most essential questions that we truly need to make the experience as frictionless as possible. We're making it simple to share their details with us and guiding them along the way."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/onboarding3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/onboarding4.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/onboarding5.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomePlay = () => {
  return (
  <ProjectStudyFeature
    id="play"
    title="Play"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Getting started."
        subtitle="Right after signing up, we have a clear answer to “What does the app do?” The app structure and navigation tells the player what the app does. To get the best experience as a part of the first session, players are encouraged to play a game and explore the app to see what can they do. All of the past and ongoing and future games are here too!"
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/play1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/play2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/play3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeNewGame = () => {
  return (
  <ProjectStudyFeature
    id="newGame"
    title="New Game"
    subtitle=""
    content={<>
      <div className="featureRow">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="Starting a game is simple but powerful."
          subtitle="With simple interface and smart defaults that progressively disclose options based on player selection."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/newGame1.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup."/>}
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          textStyle="unifiedText"
          title="Help is here!"
          subtitle="When adjusting assistance level, players can see which colors on the physical chessboard are used for move analysis based on their choosen level of assistance."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/newGame2.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup." />}
        />
      </div>

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Choosing the right opponent made easy."
        subtitle="When choosing an opponent, players can pick an AI with appropriate difficulty and assistance, play OTB (Over the Board) with another player in real life and select who it is to share the game, or play online against a friend or someone random."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/newGame3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/newGame4.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/newGame5.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="Want to play fast? Choose time format."
        subtitle="Players can choose from one of the presets or specify their own."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/newGame6.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/newGame7.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/newGame8.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Disconnected? Keep on playing."
        subtitle="When the board is not connected, players don't need to fret - they can have a great in-app chess experience!"
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/newGame9.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/newGame10.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/newGame11.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomePlaying = () => {
  return (
  <ProjectStudyFeature
    id="playing"
    title="Playing a Game"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Beautiful chess experience."
        subtitle="The app doesn't get in the way. It builds on top of the physical board experience and elevates it with more features. Players can see details about the game, last moves, AI level, assist, resign, or even accept a draft or open a beatuiful full-screen timer."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/playing1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/playing2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/playing3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <div className="featureRow">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          textStyle="unifiedText"
          title="Making the connection between board and app."
          subtitle="The chess board lights translate directly in the app."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/playing4.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup."/>}
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="What are those colors?"
          subtitle="Players can tap the questionmark button at any time to see what each color on the board means."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/playing5.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup." />}
        />
      </div>

      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="Started in the app and want to transfer to the “real thing”?"
        subtitle="Transferring a game is easy - just tap the button and set up the pieces. Or not - and let your family members play with the board and you just play on your phone."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/playing6.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/playing7.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/playing8.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="From software to hardware."
        subtitle="Loading an ongoing game, starting a learning challenge, or revisiting a past game is super easy. The app guides the player on how to set up the physical chessboard."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/playing9.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/playing10.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/playing11.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="I just need a timer."
        subtitle="No problem! Players can use the app as a timer for the physical board. Put your phone on the side and the app just melts away for maximum immersion with the physical board. Oh and it supports a landscape mode too."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/playing12.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/playing13.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/playing14.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeConnecting = () => {
  return (
  <ProjectStudyFeature
    id="connecting"
    title="Connecting"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Bluetooth pairing can be seamless."
        subtitle="The board connects automatically. And if it doesn't or if it's disconnected intentionally, it can be easily connected."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/connecting1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/connecting2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/connecting3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="I just want to play on my phone."
        subtitle="That's ok too. Players can explicitly disconnect the chessboard and let someone else in the household use it while they play on their phone."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/connecting4.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/connecting5.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/connecting6.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeLearn = () => {
  return (
  <ProjectStudyFeature
    id="learn"
    title="Learn"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="New to chess and keen to learn or are you a master wanting to practice their moves?"
        subtitle="The learn experience caters for everyone. It provides guided lesson and practices that are just pure fun!"
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="Learning made fun."
        subtitle="With interactive lessons and challenges, people are encouraged to complete each lesson and get rewarded along the way."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn4.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn5.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn6.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Lesson on the phone or with the board."
        subtitle="Each lesson provides voiceover and guided prompts to help people learn. Need to revisit a move? No problem! No audio? Just mute it. Need a hint? There it is."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn7.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn8.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn9.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
      
      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="Many challenges and puzzles."
        subtitle="Players can take on standard puzzles with clear feedback and a way to step through the challenges."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn10.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn11.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn12.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Light the square."
        subtitle="Learn the moves with instant feedback."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn13.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn14.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn15.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
      
      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="The good old questionnaire."
        subtitle="Multiple choice questions that make people think and learn."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn16.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn17.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn18.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Super piece."
        subtitle="A superb way to have fun and learn."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn19.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn20.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn21.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle="unifiedText"
        title="Add a piece."
        subtitle="This is a thinker. I didn't get it right on the first try."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/learn22.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/learn23.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/learn24.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomePractices = () => {
  return (
  <ProjectStudyFeature
    id="practices"
    title="Practices"
    subtitle=""
    content={<>
      <div className="featureRow">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="More daily challenges."
          subtitle="There's always more to learn and have fun along the way!"
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/practices1.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup."/>}
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="The most fun and challenging puzzles."
          subtitle="Revisit the classics or learn something new."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/practices2.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup." />}
        />
      </div>
    </>}
  />
  )
}

const OutcomeActivity = () => {
  return (
  <ProjectStudyFeature
    id="activity"
    title="Activity"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="All of the activity in one place."
        subtitle="Friend requests, game invites, notifications and events."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/activity1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/activity2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/activity3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeSettings = () => {
  return (
  <ProjectStudyFeature
    id="settings"
    title="Settings"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle="unifiedText"
        title="Who said settings should be boring?"
        subtitle="The app comes with smart defaults and the players share their info during onboarding, or can change things up later."
        images="tripleImage"
        imageStyle="iPhoneXFrame"
        image1={<StaticImage
          src="../../images/chessup/settings1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/settings2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/settings3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />

      <div className="featureRow">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="Find and invite friends."
          subtitle="Right on this screen."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/settings4.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup."/>}
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          textStyle="unifiedText"
          title="Control the notifications."
          subtitle="Too many events? Not anymore."
          images="singleImage"
          imageStyle="iPhoneXFrame"
          image1={<StaticImage
            src="../../images/chessup/settings5.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A ChessUp mockup." />}
        />
      </div>
    </>}
  />
  )
}

const OutcomeAndroid = () => {
  return (
  <ProjectStudyFeature
    id="android"
    title="Android"
    subtitle=""
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="tertiaryCell"
        textStyle="unifiedText"
        title="Android folks can have fun too!"
        subtitle="With an app designed with Material Design 3 in mind."
        images="tripleImage"
        imageStyle="AndroidFrame"
        image1={<StaticImage
          src="../../images/chessup/android1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image2={<StaticImage
          src="../../images/chessup/android2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
        image3={<StaticImage
          src="../../images/chessup/android3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp mockup."/>}
      />
    </>}
  />
  )
}

const ChessupOutcome = () => {
  return (
  <ProjectStudyEpic
    title="Outcome"
    subtitle=""
    content={<>
      <OutcomeOnboarding />
      <OutcomePlay />
      <OutcomeNewGame />
      <OutcomePlaying />
      <OutcomeConnecting />
      <OutcomeLearn />
      <OutcomePractices />
      <OutcomeActivity />
      <OutcomeSettings />
      <OutcomeAndroid />

      <ProjectRecommendation
        image={<StaticImage
          src="../../images/recommendations/AdamRoush.jpg"
          placeholder="blurred"
          layout="constrained"
          quality={95}
          alt="Adam Roush"/>}
        name="Adam Roush"
        title="Co-founder & Chief Design Officer at Bryght Labs"
        quote="Phil blew us away with his skill, professionalism, and his ability to make sense of our complex app requirements. His work has helped our developers work more efficiently, and we’re fortunate to have found such great talent for our project. Truly world class!"
      />
    </>}
  />
  )
}

export default ChessupOutcome