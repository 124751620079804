import React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const ChessupApproach = () => {
  return (
  <ProjectStudyEpic
    title="Approach"
    subtitle=""
    content={
    <div className="epicRow">
      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          title="The Process"
          subtitle={<>
            As a freelance senior product designer, I was responsible for the end-to-end mobile app design process – from working with the co-founders on understanding the problems we wanted to solve to defining and desgining the solutions.
            <br /><br />
            We started with a kickoff call to understand the ChessUp physical product and how it worked. The BryghtLabs team has already conducted many interviews with their customers to gather feedback and feature requests which were prioritized. There were clear business goals, constraints, established roadmap and defined user stories.
            <br /><br />
            I would then begin mapping flows and creating task lists of what users would need to do during each part of the flow. This would be followed with design - working first with rough UI, creating prototypes, gathering feedback from the stakeholders and developers, and then further refining and polishing the designs.
            <br /><br />
            Once every UI state and interaction has been thought through and defined, I would then further discuss designs with the app developer and hand over design guides.
          </>}
          images="noImages"
        />
      </div>

      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition="cover"
          cardStyle="primaryCell"
          title=""
          subtitle=""
          images="imageOnly"
          image1={<StaticImage
            src="../../images/chessup/approach.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="A ChessUp mockup."/>}
        />
      </div>
    </div>
    }
  />
  )
}

export default ChessupApproach