import * as React from "react"
import Seo from "/src/components/seo"
import "/src/styles/styles.sass"
import Header from "/src/components/header"
import Footer from "/src/components/footer"
import HireBanner from "/src/views/hire/hireBanner"
import ChessupDetails from "/src/views/chessup/chessupDetails"
import ChessupSituation from "/src/views/chessup/chessupSituation"
import ChessupApproach from "/src/views/chessup/chessupApproach"
import ChessupOutcome from "/src/views/chessup/chessupOutcome"

const CueHitPage = () => {
  return (
  <main className="mercuryView projectView projectChessUp">
    <Seo
      title="Phil Amour — ChessUp"
      description="Read my case study on ChessUp to learn about my role and approach to product design."
      image="/imageSEOChessUp.jpg"
    />
    
    <Header headerType="detail" />

    <article className="mercuryContent">
      <ChessupDetails />

      <ChessupSituation />

      <ChessupApproach />

      <ChessupOutcome />

      <section className="mercurySection contactSection">
        <HireBanner
          bannerTitle="Like the work I've done with BryghtLabs?"
          bannerSubtitle=""
        />
      </section>
    </article>
    
    <Footer />
  </main>
  )
}

export default CueHitPage
